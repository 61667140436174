import { useEffect, useMemo, useState } from "react";
import { css, cx } from "@linaria/core";

import { Meta, Layout, Section } from "~/ui";
import { Routes, Stored } from "~/models";
import { theme } from "~/theme";

import {
  YouSection,
  IntroductionSection,
  EmploymentSection,
  VolunteeringSection,
  EducationSection,
  CertificateSection,
  ContactSection,
  SkillsSection,
  ImageSection,
  AttributesSection,
} from "~/components";

export default function CVBuilder(): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [areValuesSet, setAreValuesSet] = useState({
    you: false,
    introduction: false,
    employment: false,
    volunteering: false,
    education: false,
    certificate: false,
    contact: false,
    skills: false,
    attributes: false,
  });

  useEffect(() => {
    setAreValuesSet({
      you: !!localStorage.getItem(Stored.You),
      introduction: !!localStorage.getItem(Stored.Introduction),
      employment: !!localStorage.getItem(Stored.Employment),
      volunteering: !!localStorage.getItem(Stored.Volunteering),
      education: !!localStorage.getItem(Stored.Education),
      certificate: !!localStorage.getItem(Stored.Certificate),
      contact: !!localStorage.getItem(Stored.Contact),
      skills: !!localStorage.getItem(Stored.Skills),
      attributes: !!localStorage.getItem(Stored.Attributes),
    });
    setIsLoading(false);
  }, []);

  const isFormEmpty = useMemo(() => {
    return !(
      areValuesSet.you ||
      areValuesSet.introduction ||
      areValuesSet.employment ||
      areValuesSet.volunteering ||
      areValuesSet.education ||
      areValuesSet.certificate ||
      areValuesSet.contact ||
      areValuesSet.skills ||
      areValuesSet.attributes
    );
  }, [areValuesSet]);

  const handleOnSectionUpdate = (
    sectionTitle: keyof typeof Stored,
    localStorageTitle: Stored
  ): void => {
    const key = sectionTitle.toLocaleLowerCase();
    const existsInLocalStorage = !!localStorage.getItem(localStorageTitle);
    setAreValuesSet((prev) => ({ ...prev, [key]: existsInLocalStorage }));
  };

  if (isLoading) {
    return null;
  }

  return (
    <Layout backgroundColor="dark">
      <Section bgColor="dark">
        <div className="row">
          <div className="col-12 col-md-8 mb-4">
            <h1 className="d-flex flex-column text-light mb-4">
              Job and volunteer
              <span className="display-1">CV Template</span>
            </h1>
            <p className="lead text-light mb-0">
              Use this template to begin building a resume. You can return here
              to continue adding your experiences as you go.
            </p>
          </div>
          <div className="col-12 col-md-4 d-flex align-items-end justify-content-center mb-md-4">
            {isFormEmpty ? (
              <button
                disabled
                aria-disabled={isFormEmpty}
                className="btn btn-light"
              >
                Download as PDF
              </button>
            ) : (
              <a
                href={Routes.CvDownload}
                target="_blank"
                rel="noopener noreferrer"
                title="View your downloadable CV"
                className="btn btn-warning"
              >
                Download as PDF
              </a>
            )}
          </div>
        </div>
      </Section>

      <Section
        bgColor="offWhite"
        baseBgColorTop="dark"
        roundedCorners={{ "top-left": true }}
        className="p-0"
      >
        <div
          className="row"
          style={{
            ["--grey-200" as string]: theme.gray200,
          }}
        >
          <div className="col-12 col-lg-8 pt-5 px-4 mb-4">
            <YouSection onUpdate={handleOnSectionUpdate} />
            <IntroductionSection onUpdate={handleOnSectionUpdate} />
            <EmploymentSection onUpdate={handleOnSectionUpdate} />
            <VolunteeringSection onUpdate={handleOnSectionUpdate} />
            <EducationSection onUpdate={handleOnSectionUpdate} />
            <CertificateSection onUpdate={handleOnSectionUpdate} />
          </div>

          <div
            className={cx(
              "col-12 col-lg-4 py-3 py-md-5 px-4",
              css`
                background-color: var(--grey-200);
              `
            )}
          >
            <ImageSection onUpdate={handleOnSectionUpdate} />
            <ContactSection onUpdate={handleOnSectionUpdate} />
            <SkillsSection onUpdate={handleOnSectionUpdate} />
            <AttributesSection onUpdate={handleOnSectionUpdate} />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Resources - CV Builder" url={Routes.CvBuilder} />;
};
